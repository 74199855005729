import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IManufacturingCapabilities } from './capability.model';
import { CapabilityStore } from './capability.store';

@Injectable({ providedIn: 'root' })
export class CapabilityQuery extends Query<IManufacturingCapabilities> {
  public constructor(protected store: CapabilityStore) {
    super(store);
  }
}
