import { Component } from '@angular/core';

@Component({
  selector: 'app-malicious-file-warning',
  standalone: true,
  imports: [],
  template: `
    <div style="line-height: 20px;">
      It appears that the file you received may be malicious. For your security,
      please avoid opening the file. If you have any concerns, contact support
      for assistance. Stay safe!
    </div>
  `,
})
export class MaliciousFileWarningComponent {}
