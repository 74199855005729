import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  OktaService,
  BaseOktaApi,
  VendorFormatService,
} from 'sustainment-component';
import {
  ArticlesResponse,
  IGeoqueryResponse,
  IGeoqueryResRow,
} from 'sustainment-models';
import { IResourceResponseModel } from '../store/resources/resources.model';

@Injectable()
export class DataProductsAPI extends BaseOktaApi {
  protected urlBase: string;
  public constructor(
    httpClient: HttpClient,
    oktaService: OktaService,
    private vendorFormat: VendorFormatService
  ) {
    super(httpClient, oktaService);
    this.urlBase = environment.dataProducts;
  }

  public getResourceData(state: string): Observable<IResourceResponseModel[]> {
    return this.oktaAuthHeader('').pipe(
      mergeMap((headers) => {
        const url = this.urlBase + '/resources?state=' + state;
        return this._http.get<IResourceResponseModel[]>(url, { headers });
      })
    );
  }

  public getOnboardingResults(
    body: VendorSearchNearDetailModel
  ): Observable<IGeoqueryResponse> {
    return this.oktaAuthHeader('').pipe(
      mergeMap((headers) => {
        return this._http.post<IGeoqueryResponse>(
          this.urlBase + '/geosearch/onboarding',
          body,
          { headers }
        );
      })
    );
  }

  public getCompanyWeekRecentArticles(): Observable<ArticlesResponse> {
    return this.oktaAuthHeader('').pipe(
      mergeMap((headers) => {
        return this._http.get<ArticlesResponse>(
          this.urlBase + '/news-feed/articles/dashboard',
          { headers }
        );
      })
    );
  }

  public getOnboardingPreselect(
    odysseusId: string
  ): Observable<IGeoqueryResRow> {
    return this.oktaAuthHeader('').pipe(
      mergeMap((headers) => {
        return this._http.post<{ data: IGeoqueryResRow }>(
          this.urlBase + '/geosearch/onboarding',
          { odysseusId },
          { headers }
        );
      }),
      map((x) => {
        const data = x.data;
        return {
          ...data,
          details: {
            ...data.details,
            logoUrl: this.vendorFormat.getLogo(
              data.ids.sustainment,
              data.details.logo
            ),
          },
        };
      })
    );
  }
}

export interface VendorSearchNearDetailModel {
  lat: number;
  long: number;
  textSearch: string;
}
