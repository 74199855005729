import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { VendorState } from 'sustainment-models';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'vendorInfo' })
export class VendorStore extends Store<VendorState> {
  public constructor() {
    super({});
  }
}
