/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Feature, Organization, UserSession } from 'sustainment-models';
import { IUserAccountState } from './user-account.model';
import { UserAccountStore } from './user-account.store';

@Injectable({ providedIn: 'root' })
export class UserAccountQuery extends Query<IUserAccountState> {
  public userAccount$ = this.select((state) => state.userAccount);
  public userSession$ = this.select((state) => state.userSession);

  public get sustainmentId(): string {
    return this.getValue().sustainmentId;
  }

  public get username(): string {
    const user = this.getValue().userAccount;
    if (!user) return '';
    return `${user.firstName} ${user.lastName}`;
  }

  public get email(): string {
    const user = this.getValue().userAccount;
    if (!user) return '';
    return user.email;
  }

  public get max_users(): number {
    const userSession = this.getValue().userSession;
    const featureWithQuantity = userSession?.features?.find(
      (e: Feature) => e.userQuantity !== null
    );
    return featureWithQuantity?.userQuantity ?? 0;
  }

  public get max_projects(): number {
    const userSession = this.getValue().userSession;
    const featureWithQuantity = userSession?.features?.find(
      (e: Feature) => e.projectQuantity !== null
    );
    return featureWithQuantity?.projectQuantity ?? 0;
  }

  public get haveBlueprintFeature(): boolean {
    const userSession = this.getValue().userSession;
    return (
      !!userSession &&
      userSession.features?.some((e: Feature) => e.key == 'BLUEPRINT')
    );
  }

  public get havePaperlessPartsFeature(): boolean {
    const userSession = this.getValue().userSession;
    return (
      !!userSession &&
      userSession.features?.some((e: Feature) => e.key == 'PAPERLESS PARTS')
    );
  }

  public get role(): number | null {
    return this.getValue().userSession?.role || null;
  }

  public get isAdmin(): boolean {
    return this.getValue().userSession?.role === 1;
  }

  public get session(): UserSession | null {
    return this.getValue().userSession;
  }

  public get currentOrganization(): Organization | null {
    const userAccount = this.getValue().userAccount;
    return (
      userAccount?.organizations.find(
        (c) => c.sustainmentId === this.sustainmentId
      ) || null
    );
  }

  public getUserId(): string {
    return this.getValue().userId;
  }

  public constructor(protected store: UserAccountStore) {
    super(store);
  }
}
