export const regex = {
  emailValidatorPattern:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>([\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  websiteUrlValidatorPattern:
    /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  linkedInUrlValidatorPattern:
    /^(https:\/\/www.linkedin.com\/)[a-zA-Z0-9/]{2,}/,
  numericPattern: /^[0-9]*$/,
  numeric2digitPattern: /^-?\d*(\.\d{0,2})?$/,
  phonefaxnumber:
    /^\(?([2-9][0-9]{2})\)?[-. ]?([2-9](?!11)[0-9]{2})[-. ]?([0-9]{4})$/,
  datePattern: /^(0?[1-9]|1[0-2])[/](0?[1-9]|[12]\d|3[01])[/](19|20)\d{2}$/,
  zipPattern: /^\d{5}$/,
  yearPattern: /^(181[2-9]|18[2-9]\d|19\d\d|2\d{3}|30[0-3]\d|304[0-8])$/,
};
