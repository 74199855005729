<div class="company-type">
  <div class="grid">
    <div class="col-12">
      <div class="registration-title">How will you use Sustainment?</div>
    </div>
  </div>
  <div class="row registration-sub-title company-type-sub-title">
    <div class="col-12">Please select all that apply.</div>
  </div>
  <div class="type-container">
    <div
      class="type-item"
      [ngClass]="{ 'type-selected': selectedBuyer }"
      (click)="onCompanyBuyerChanged()"
    >
      <div class="type-item-icon">
        <span class="report-money-icon"></span>
      </div>
      <div class="type-item-body">
        <div class="type-item-title">I'm a buyer</div>
        <div class="type-item-subtitle">
          I’ll manage supplier relationships and run projects with them.
        </div>
      </div>
      <div class="type-item-check">
        <p-checkbox [ngModel]="selectedBuyer" [binary]="true"></p-checkbox>
      </div>
    </div>

    <div
      class="type-item"
      (click)="onCompanySupplierChange()"
      [ngClass]="{ 'type-selected': selectedSupplier }"
    >
      <div class="type-item-icon">
        <span class="tool-icon"></span>
      </div>
      <div class="type-item-body">
        <div class="type-item-title">I'm a supplier</div>
        <div class="type-item-subtitle">
          I’ll set up a profile and explore new opportunities with customers.
        </div>
      </div>
      <div class="type-item-check">
        <p-checkbox [ngModel]="selectedSupplier" [binary]="true"></p-checkbox>
      </div>
    </div>
    <div
      class="type-item"
      [ngClass]="{
        'type-selected': selectedOther,
        disabled: selectedBuyer || selectedSupplier,
        invalid: multiselectInvalid
      }"
      [pTooltip]="
        'You can only select Buyer, Buyer & Supplier, Supplier or Other'
      "
      tooltipPosition="bottom"
      [tooltipDisabled]="!selectedBuyer && !selectedSupplier"
    >
      <div class="type-item-icon">
        <span class="other-icon"></span>
      </div>
      <div class="type-item-body">
        <div class="type-item-title">Other</div>
        <div class="type-item-subtitle">
          I’m neither a buyer, nor a supplier, but part of the Manufacturing
          industry
        </div>
        <div class="other-types-multiselect-container">
          <p-multiSelect
            *ngIf="selectedOther"
            [options]="types"
            [(ngModel)]="selectedOtherType"
            optionLabel="name"
            [showHeader]="false"
            styleClass="other-types-multiselect"
            (onChange)="onCompanyOtherTypeChange()"
          ></p-multiSelect>
          <div class="error-text" *ngIf="multiselectInvalid">
            Please make a selection
          </div>
        </div>
      </div>
      <div class="type-item-check">
        <p-checkbox
          [ngModel]="selectedOther"
          [binary]="true"
          [disabled]="selectedBuyer || selectedSupplier"
          (click)="
            !selectedBuyer && !selectedSupplier
              ? onCompanyOtherChange($event)
              : null
          "
        ></p-checkbox>
      </div>
    </div>
  </div>
</div>
