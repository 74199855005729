import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  ProjectCreateRequestModel,
  ProjectsAPI,
} from 'src/app/api/projects.api';
import {
  IProject,
  IProjectList,
  IProjectInitial,
  IProjectVisibility,
  ProjectModel,
} from 'sustainment-models';
import { PublicOpportunitiesAction } from '../public-opportunities/public-opportunities.action';
import { ProjectsStore } from './projects.store';
import { BoxApi, NotifyService } from 'sustainment-component';
import {
  AwardedProject,
  ReceivingQuoteProject,
  DraftProject,
  ClosedProject,
} from 'src/app/store/projects/projects.model';

@Injectable({ providedIn: 'root' })
export class ProjectsActions {
  public constructor(
    private projectsAPI: ProjectsAPI,
    private store: ProjectsStore,
    private boxApi: BoxApi,
    private opportunityActions: PublicOpportunitiesAction,
    private notifyService: NotifyService
  ) {}

  public getProjectsData(): void {
    if (Object.keys(this.store.getValue()).length) this.store.setLoading(true);
    this.projectsAPI
      .getProjects()
      .pipe(
        tap((res) => {
          this.store.update(() => ({
            projects: res?.projects?.map((project) => ({
              ...project,
              notifications: project.notifications,
              unread: project.notifications,
            })),
          }));
          this.store.setLoading(false);
        })
      )
      .subscribe();

    this.projectsAPI
      .getOtherProjects()
      .pipe(
        tap((res) => {
          this.store.update(() => ({
            otherProjects: {
              closed: res.closed,
              inProgress: res.inProgress,
              invitations: res.invitations,
              lost: res.lost,
              quoting: res.quoting,
            },
          }));

          this.store.setLoading(false);
        })
      )
      .subscribe();
  }

  public removeProject(project: DraftProject): void {
    this.store.setLoading(true);
    this.projectsAPI.deleteProject(project.projectId).subscribe(() => {
      this.store.update((currentValue) => ({
        projects: currentValue?.projects?.filter(
          (p: IProjectList) => p.externalId !== project.projectId
        ),
      }));
      this.store.setLoading(false);
      this.opportunityActions.getPublicOpportunitiesData(true);
    });
  }

  public addProject(project: IProjectInitial): Observable<IProject> {
    const files = project.files;
    delete project.files;
    this.store.setLoading(true);
    return this.projectsAPI.saveProject(project).pipe(
      concatMap((newProject: IProject) => {
        if (files && files.length > 0) {
          return this.boxApi
            .handleProjectFiles(
              newProject.externalId,
              newProject.publicBoxFolderId,
              files
            )
            .pipe(map(() => newProject));
        } else {
          return of(newProject);
        }
      }),
      tap(() => {
        this.store.setLoading(false);
        this.getProjectsData();
      }),
      catchError((err) => {
        this.store.setLoading(false);
        throw err;
      })
    );
  }

  public updateProject(project: ProjectModel): Observable<IProject> {
    this.store.setLoading(true);
    return this.projectsAPI.updateProject(project).pipe(
      tap(() => {
        this.store.setLoading(false);
        this.getProjectsData();
      }),
      catchError((err) => {
        this.store.setLoading(false);
        throw err;
      })
    );
  }

  public updateOpenProject(
    externalId: string,
    project: IProjectVisibility
  ): Observable<IProjectVisibility> {
    this.store.setLoading(true);
    return this.projectsAPI.updateOpenProject(externalId, project).pipe(
      tap(() => {
        this.store.setLoading(false);
        this.getProjectsData();
      }),
      catchError((err) => {
        this.store.setLoading(false);
        throw err;
      })
    );
  }

  public validateProjectUniqueName(name: string): Observable<boolean> {
    return this.projectsAPI.validateUniqueName(name);
  }

  public initializeProject(project: ProjectModel): Observable<void> {
    this.store.setLoading(true);
    return this.updateProject(project).pipe(
      switchMap(() => this.projectsAPI.initiateProject(project)),
      tap(() => {
        this.opportunityActions.getPublicOpportunitiesData(true);
        this.store.setLoading(false);
      })
    );
  }

  public acceptProject(projectId: string): Observable<void> {
    this.store.setLoading(true);
    return this.projectsAPI.acceptInvite(projectId).pipe(
      tap(() => {
        this.store.update((currentValue) => {
          const projectIndex = currentValue.otherProjects.invitations.findIndex(
            (p) => p.externalId === projectId
          );
          const invitations = [...currentValue.otherProjects.invitations];
          const inProgress = [...currentValue.otherProjects.inProgress];
          const project = invitations.splice(projectIndex, 1)[0];
          const newProject = {
            ...project,
            memberStatus: { id: 1, status: 'Accepted' },
            notifications: {
              ...project.notifications,
              pending: false,
            },
          };

          inProgress.unshift(newProject);

          return {
            otherProjects: {
              ...currentValue.otherProjects,
              inProgress,
              invitations,
            },
          };
        });

        this.getPendingCount();
        this.store.setLoading(false);
        this.notifyService.showSuccess('Invitation accepted successfully.');
        this.opportunityActions.getPublicOpportunitiesData(true);
      })
    );
  }

  public rejectInvite(projectId: string): Observable<unknown> {
    this.store.setLoading(true);
    return this.projectsAPI.rejectInvite(projectId).pipe(
      tap(() => {
        this.store.update((currentValue) => ({
          otherProjects: {
            ...currentValue.otherProjects,
            invitations: currentValue.otherProjects.invitations.filter(
              (x) => x.externalId !== projectId
            ),
          },
        }));
        this.getPendingCount();
        this.store.setLoading(false);
        this.opportunityActions.getPublicOpportunitiesData(true);
      })
    );
  }

  public leaveProject(projectId: string, message: string): Observable<unknown> {
    this.store.setLoading(true);
    return this.projectsAPI.noQuoteProject(projectId, message).pipe(
      tap(() => {
        this.store.update((currentValue) => {
          const pendingProject = currentValue.otherProjects.invitations.find(
            (x) => x.externalId === projectId
          );
          let quoting = currentValue.otherProjects.quoting;
          if (pendingProject) {
            const updatedProject = {
              ...pendingProject,
              status: 'No Quote',
              memberStatus: {
                id: 5,
                status: 'No Quote',
              },
            };
            quoting = quoting.concat(updatedProject);
            currentValue.otherProjects.invitations.filter(
              (x) => x.externalId !== updatedProject.externalId
            );
          }
          return {
            otherProjects: {
              ...currentValue.otherProjects,
              quoting: quoting,
              invitations: currentValue.otherProjects.invitations.filter(
                (x) => x.externalId !== projectId
              ),
            },
          };
        });
        this.store.setLoading(false);
      })
    );
  }

  public getPendingCount(): void {
    this.store.setLoading(true);
    this.projectsAPI.getProjectPendingCount().subscribe((x) => {
      this.store.update({
        pendingCount: x.total,
      });
      this.store.setLoading(false);
    });
  }

  public completeProject(
    project:
      | AwardedProject
      | ReceivingQuoteProject
      | DraftProject
      | ClosedProject,
    completeData: {
      isCompleted: boolean;
      projectRating?: number | undefined;
      meetTimeExpectations?: boolean | undefined;
      meetBudgetExpectations?: boolean | undefined;
    }
  ): void {
    this.store.setLoading(true);
    this.projectsAPI
      .completeProject(project.projectId, completeData)
      .subscribe(() => {
        this.store.update((value) => ({
          projects: value.projects.map((p) =>
            project.projectId === p.externalId
              ? { ...p, status: 'Completed' }
              : p
          ),
        }));
      });
  }

  public createDraftProject(
    data: ProjectCreateRequestModel,
    trackEvent: boolean
  ): Observable<{ id: string }> {
    this.store.setLoading(true);
    return this.projectsAPI
      .createDraft(data, trackEvent)
      .pipe(tap(() => this.store.setLoading(false)));
  }

  public updateDraftProject(
    projectId: string,
    data: ProjectCreateRequestModel,
    trackEvent: boolean,
    filesToDelete?: string[]
  ): Observable<void> {
    this.store.setLoading(true);
    const fileDeletion$ = filesToDelete?.length
      ? forkJoin(
          filesToDelete.map((file, i) =>
            this.projectsAPI
              .deleteFile(projectId, file)
              .pipe(tap(() => filesToDelete.splice(i, 1)))
          )
        )
      : of([]);

    return fileDeletion$.pipe(
      mergeMap(() => this.projectsAPI.updateDraft(projectId, data, trackEvent)),
      tap(() => this.store.setLoading(false))
    );
  }

  public openDraftProject(projectId: string): Observable<void> {
    this.store.setLoading(true);
    return this.projectsAPI.openDraftProject(projectId).pipe(
      tap(() => this.store.setLoading(false)),
      tap(() => this.getProjectsData())
    );
  }
}
