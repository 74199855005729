import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CompanyType } from 'sustainment-models';

interface OtherType {
  name: string;
  code: string;
}
// need to move this to sustainment-models

@Component({
  selector: 'app-registration-company-type',
  templateUrl: './company-type.component.html',
  styleUrls: ['./company-type.component.scss'],
})
export class RegistrationCompanyTypeComponent implements OnInit {
  @Input() public companyType: CompanyType[] = [];
  @Output() public companyTypeChange = new EventEmitter<CompanyType[]>();
  @Output() public enableContinue = new EventEmitter<boolean>();

  public selectedBuyer: boolean;
  public selectedSupplier: boolean;
  public selectedOther: boolean;
  public multiselectInvalid: boolean;

  public types: OtherType[];

  public selectedOtherType: OtherType[] = [];

  public ngOnInit(): void {
    this.types = [
      { name: 'I provide raw materials.', code: 'raw' },
      { name: 'I sell products and services.', code: 'seller' },
      { name: `I'm a support organization.`, code: 'support' },
    ];

    if (this.companyType.length) {
      this.selectedBuyer = !!this.companyType.find(
        (x) => x === CompanyType.buyer
      );
      this.selectedSupplier = !!this.companyType.find(
        (x) => x === CompanyType.supplier
      );

      if (
        this.companyType.findIndex((x) => x === CompanyType.isRawMaterial) > -1
      ) {
        this.selectedOtherType.push(
          this.types.filter((t) => t.code === 'raw')[0]
        );
      }

      if (this.companyType.findIndex((x) => x === CompanyType.isSeller) > -1) {
        this.selectedOtherType.push(
          this.types.filter((t) => t.code === 'seller')[0]
        );
      }

      if (this.companyType.findIndex((x) => x === CompanyType.isSupport) > -1) {
        this.selectedOtherType.push(
          this.types.filter((t) => t.code === 'support')[0]
        );
      }

      this.enableContinueChanged();
    }
  }

  public onCompanyBuyerChanged(): void {
    this.selectedBuyer = !this.selectedBuyer;
    this.selectedOtherType = [];
    this.selectedOther = false;
    this.multiselectInvalid = false;
    this.enableContinueChanged();
    this.setCompanyTypeChanged();
  }

  public onCompanySupplierChange(): void {
    this.selectedSupplier = !this.selectedSupplier;
    this.selectedOtherType = [];
    this.selectedOther = false;
    this.multiselectInvalid = false;
    this.enableContinueChanged();
    this.setCompanyTypeChanged();
  }

  public onCompanyOtherChange(event: Event): void {
    event.stopPropagation();
    this.selectedOther = !this.selectedOther;
    this.enableContinueChanged();
    this.setCompanyTypeChanged();
  }

  public onCompanyOtherTypeChange(): void {
    if (this.selectedOtherType.length > 0) {
      this.selectedSupplier = false;
      this.selectedBuyer = false;
      this.multiselectInvalid = false;
    } else {
      this.multiselectInvalid = true;
    }

    this.setCompanyTypeChanged();
    this.enableContinueChanged();
  }

  private enableContinueChanged(): void {
    this.enableContinue.emit(
      this.selectedBuyer ||
        this.selectedSupplier ||
        this.selectedOtherType.length > 0
    );
  }

  private setCompanyTypeChanged(): void {
    this.companyType = [];
    if (this.selectedBuyer) {
      this.companyType.push(CompanyType.buyer);
    }
    if (this.selectedSupplier) {
      this.companyType.push(CompanyType.supplier);
    }
    if (this.selectedOtherType.findIndex((x) => x.code === 'raw') > -1) {
      this.companyType.push(CompanyType.isRawMaterial);
    }
    if (this.selectedOtherType.findIndex((x) => x.code === 'seller') > -1) {
      this.companyType.push(CompanyType.isSeller);
    }
    if (this.selectedOtherType.findIndex((x) => x.code === 'support') > -1) {
      this.companyType.push(CompanyType.isSupport);
    }
    this.companyTypeChange.emit(this.companyType);
  }
}
