import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { SustainmentButtonModule } from 'sustainment-component';

@Component({
  selector: 'app-malicious-file-warning-footer',
  standalone: true,
  imports: [SustainmentButtonModule],
  template: `
    <div
      style="display: flex;
      justify-content: flex-end;
      gap: 8px;"
    >
      <s-button type="primary" label="Close" (click)="closeDialog()"></s-button>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
})
export class MaliciousFileWarningFooterComponent {
  public constructor(public ref: DynamicDialogRef) {}

  public closeDialog(): void {
    this.ref.close();
  }
}
