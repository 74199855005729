<div class="login-container registration">
  <div class="sustainment-logo"></div>

  <div class="widget-container">
    <div class="registration-section-container">
      <app-company-preselection
        *ngIf="refCode === null && (preloadVendor$ | async) as preload; else wizard"
        [vendor]="preload"
        [processes]="processes"
        [industries]="industries"
        (canceled)="cancelPreselection()"
        (selected)="onFinishRegistration($event)"
        [originalProcesses]="originalProcesses"
      ></app-company-preselection>
      <ng-template #wizard>
        <ng-container *ngIf="loaded; else spinner">
          <app-registration
            *ngIf="!isSaved"
            [vendorInfo]="vendorInfo"
            (logout)="logout()"
            (vendorUpdated)="updateVendorInfo($event)"
            (patchVendor)="onFinishRegistration($event)"
            (requestedToJoin)="requestToJoinCompany($event)"
            [processes]="processes"
            [industries]="industries"
            [autoCompleteOnboarding]="autoCompleteOnboarding"
          ></app-registration>
          <app-welcome-screen
            *ngIf="isSaved && isNotBuyer"
            [id]="sustainmentid"
          ></app-welcome-screen>
        </ng-container>
      </ng-template>
      <div class="row registration-comments">
        <div class="col-12">
          By creating an account. you understand and agree to Sustainment
          <a
            class="link-detail"
            href="https://sustainment.tech/terms-of-use/"
            target="_blank"
            >Terms of Service</a
          >
          including the
          <a
            class="link-detail"
            href="https://sustainment.tech/privacy-policy/"
            target="_blank"
            >User Agreement and Privacy Policy</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="sustainment-logo-transparent"></div>
</div>

<ng-template #spinner>
  <div id="overlay" class="spinner">
    <i class="pi pi-spin pi-spinner" style="font-size: 5rem"></i></div
></ng-template>

<p-confirmDialog
  [key]="'cancelOnboardingPreselection'"
  [closable]="true"
  [baseZIndex]="10000"
  styleClass="dialog-sm"
></p-confirmDialog>
