import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { VendorProfile, VendorState } from 'sustainment-models';
import { VendorStore } from './vendor.store';

@Injectable({ providedIn: 'root' })
export class VendorQuery extends Query<VendorState> {
  public constructor(protected store: VendorStore) {
    super(store);
  }

  public get alias(): string {
    return (this.getValue().vendor as any).alias;
  }
  public vendorInfo$: Observable<VendorProfile> = this.select((s) => s.vendor);
}
