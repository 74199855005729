import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  BaseApi,
  FileHistoryModel,
  VendorFormatService,
} from 'sustainment-component';
import {
  DetailsResponse,
  OverallResponse,
} from 'sustainment-component/lib/reusable/components/my-suppliers-analytics/performance/performance.component';
import {
  AcceptDeclinePO,
  IBoxLogModel,
  IOtherProjectList,
  IPriority,
  IProject,
  IProjectActiveInvitation,
  IProjectCreateInvitation,
  IProjectInitial,
  IProjectList,
  IProjectMultipleInvitation,
  IProjectVisibility,
  ProjectModel,
  ProjectUpdateSettingsModel,
  TokenResponse,
  UpdatePurchaseOrderFilesDto,
} from 'sustainment-models';
import { UserAccountQuery } from '../store/userAccount/user-account.query';
import { VendorQuery } from '../store/vendor/vendor.query';
import {
  AwardedProject,
  ClosedProject,
  DraftProject,
  ReceivingQuoteProject,
} from '../store/projects/projects.model';
import { Quote } from '../containers/projects/projects-view/quotes/project-quotes/project-quotes.component';
import { PurchaseOrder } from '../containers/projects/projects-view/purchase-order/project-po.component';
import { MaliciousFileWarningComponent } from '../components/ui/malicious-file-warning/malicious-file-warning.component';
import { MaliciousFileWarningHeaderComponent } from '../components/ui/malicious-file-warning/header';
import { MaliciousFileWarningFooterComponent } from '../components/ui/malicious-file-warning/footer';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable()
export class ProjectsAPI extends BaseApi {
  protected urlBase: string;
  private _relativeUrl = 'project';
  private _vendorName: string;

  public constructor(
    public _http: HttpClient,
    private _userAccountQuery: UserAccountQuery,
    private vendorQuery: VendorQuery,
    private dialogService: DialogService,
    private vendorFormatService: VendorFormatService
  ) {
    super(_http);
    this.urlBase = environment.projectsApiBaseUrl;

    this.vendorQuery
      .select((v) => v.vendor?.name)
      .subscribe((v) => (this._vendorName = v || ''));
  }

  public getProject(projectId: string): Observable<ProjectModel> {
    return this.get<ProjectModel>(this._relativeUrl + `/${projectId}`).pipe(
      map((p) => ({
        ...p,
        memberStatus: p.memberStatus,
        members: p.members.map((m) => ({
          ...m,
          logoUrl: this.vendorFormatService.getLogo(
            m.sustainmentId!,
            m.logo || ''
          ),
          coverUrl: this.vendorFormatService.getCover(
            m.sustainmentId!,
            m.cover || ''
          ),
        })),
        invitations: p.invitations?.map((invitation) => ({
          ...invitation,
          logoUrl: this.vendorFormatService.getLogo(
            invitation.sustainmentId,
            invitation.logo || ''
          ),
        })),
        requests: p.requests?.map((r) => ({
          ...r,
          requester: {
            ...r.requester,
            logoUrl: this.vendorFormatService.getLogo(
              r.requester.sustainmentId!,
              r.requester.logo || ''
            ),
          },
        })),
      }))
    );
  }

  public getProjects(): Observable<ProjectListModel> {
    return this.get<ProjectListModel>(this._relativeUrl);
  }

  public getOtherProjects(): Observable<UserOpportunitiesResponse> {
    return this.get<UserOpportunitiesResponse>(this._relativeUrl + '/other');
  }

  public saveProject(project: IProjectInitial): Observable<IProject> {
    return this.post<IProject>(this._relativeUrl, project);
  }

  public deleteProject(projectId: string): Observable<unknown> {
    return this.delete(this._relativeUrl + `/${projectId}`);
  }

  public updateProject(project: ProjectModel): Observable<IProject> {
    return this.put<IProject>(
      this._relativeUrl + `/${project.externalId}`,
      project
    );
  }

  public addTeamMember(projectId: string, userId: string): Observable<void> {
    return this.put<void>(
      this._relativeUrl + `/${projectId}/member/${userId}`,
      {}
    );
  }

  public deleteTeamMember(projectId: string, userId: string): Observable<void> {
    return this.delete(this._relativeUrl + `/${projectId}/member/${userId}`);
  }

  public createDraft(
    body: ProjectCreateRequestModel,
    trackEvent: boolean
  ): Observable<{ id: string }> {
    return this.post<{ id: string }>(`drafts?trackEvent=${trackEvent}`, body);
  }

  public updateDraft(
    projectId: string,
    body: ProjectCreateRequestModel,
    trackEvent: boolean
  ): Observable<void> {
    return this.put<void>(`drafts/${projectId}?trackEvent=${trackEvent}`, body);
  }

  public openDraftProject(projectId: string): Observable<void> {
    return this.put<void>(`drafts/${projectId}/open`, {});
  }

  public updateOpenProject(
    externalId: string,
    project: IProjectVisibility
  ): Observable<IProject> {
    return this.put<IProject>(
      this._relativeUrl + `/open/${externalId}`,
      project
    );
  }

  public getPriorities(): Observable<IPriority[]> {
    return this.get<IPriority[]>(this._relativeUrl + `/priorities`);
  }

  public initiateProject(project: ProjectModel): Observable<void> {
    return this.post(
      this._relativeUrl + `/${project.externalId}/initiate`,
      project
    );
  }

  //Vendor invite
  public inviteVendor(
    projectId: string,
    sustainmentId: string
  ): Observable<void> {
    return this.post(this._relativeUrl + `/${projectId}/vendor`, {
      sustainmentId: sustainmentId,
    });
  }

  public acceptInvite(projectId: string): Observable<void> {
    return this.put(this._relativeUrl + `/${projectId}/invitation`, {});
  }

  public rejectInvite(projectId: string): Observable<void> {
    return this.put(this._relativeUrl + `/${projectId}/reject`, {});
  }

  public validateUniqueName(projectName: string): Observable<boolean> {
    return this.get(
      `${this._relativeUrl}/project-name-unique?projectName=${projectName}`
    );
  }

  public removeInvite(
    projectId: string,
    vendorId: string
  ): Observable<unknown> {
    return this.delete(this._relativeUrl + `/${projectId}/vendor/${vendorId}`);
  }

  public leaveProject(projectId: string): Observable<unknown> {
    return this.delete(this._relativeUrl + `/${projectId}/leave`);
  }

  public noQuoteProject(
    projectId: string,
    message: string
  ): Observable<unknown> {
    return this._http.delete(this.urlBase + `project/${projectId}/no-quote`, {
      body: { message },
    });
  }

  public openQuote(projectId: string): Observable<unknown> {
    return this._http.put(this.urlBase + `project/${projectId}/no-quote`, {
      body: {},
    });
  }

  public settingProject(
    projectId: string,
    model: ProjectUpdateSettingsModel
  ): Observable<void> {
    return this.post(this._relativeUrl + `/${projectId}/settings`, model);
  }

  public createInvitation(
    projectId: string,
    model: IProjectCreateInvitation
  ): Observable<void> {
    return this.post(this._relativeUrl + `/${projectId}/vendor-invite`, {
      inviterName: this._userAccountQuery.username,
      inviterCompany: this._vendorName,
      ...model,
    });
  }

  public logBoxEvent(projectId: string, model: IBoxLogModel): Observable<void> {
    return this.post(
      this._relativeUrl + `/collaboration/${projectId}/log`,
      model
    );
  }

  public getProjectUnread(projectId: string): Observable<unknown> {
    return this.get(this._relativeUrl + `/unread/${projectId}`);
  }

  public getProjectPendingCount(): Observable<{ total: number }> {
    return this.get(this._relativeUrl + '/other/pending-count');
  }

  public removeVendorInvitation(
    projectId: string,
    vendorOdysseusId: string
  ): Observable<void> {
    return this.delete(
      `${this._relativeUrl}/${projectId}/vendor-invite/${vendorOdysseusId}`
    );
  }

  public getActiveProjectsInvitation(
    removeVendorId?: string
  ): Observable<IProjectActiveInvitation[]> {
    let baseUrl = `${this._relativeUrl}/active-project-invitation`;

    if (removeVendorId) baseUrl = `${baseUrl}?removeVendorId=${removeVendorId}`;

    return this.get<IProjectActiveInvitation[]>(baseUrl);
  }

  public inviteMultipleVendors(
    body: IProjectMultipleInvitation[]
  ): Observable<void> {
    const baseUrl = `${this._relativeUrl}/invite-multiple-vendors`;

    return this.post(baseUrl, body);
  }

  public getUploadToken(
    projectId: string,
    memberId: string
  ): Observable<{ token: string; folderId: string }> {
    return this.post(
      `${this._relativeUrl}/${projectId}/upload-token`,
      `"${memberId}"`
    );
  }

  public getUploadTokensForAllMembers(
    projectId: string
  ): Observable<ProjectMembersToken[]> {
    return this.get(`${this._relativeUrl}/${projectId}/tokens`);
  }

  public getProjectUploadToken(projectId: string): Observable<string> {
    return this.get(`${this._relativeUrl}/${projectId}/upload-token`);
  }

  public downloadFile(
    projectId: string,
    fileId: string
  ): Observable<{ url: string; scanResult: string } | null> {
    return this.get<{ url: string; scanResult: string }>(
      `${this._relativeUrl}/${projectId}/download/${fileId}`
    ).pipe(
      map((res) => {
        if (res.scanResult === 'Malicious') {
          this.dialogService.open(MaliciousFileWarningComponent, {
            templates: {
              header: MaliciousFileWarningHeaderComponent,
              footer: MaliciousFileWarningFooterComponent,
            },
          });
          res.url = '';
        }
        return res;
      }),
      catchError((error) => {
        if (error.status === 400) return of(null);
        return throwError(() => error);
      })
    );
  }

  public getProjectTeam(projectId: string): Observable<string[]> {
    return this.get(`${this._relativeUrl}/${projectId}/team`);
  }

  public getFileHistory(
    projectId: string,
    userId: string
  ): Observable<FileHistoryModel[]> {
    return this.get(`${this._relativeUrl}/${projectId}/files/${userId}`);
  }

  public deleteFile(projectId: string, fileId: string): Observable<void> {
    return this.delete(`${this._relativeUrl}/${projectId}/files/${fileId}`);
  }

  public awardProject(
    projectId: string,
    memberId: string,
    data: { amount: number; deliverydate: Date }
  ): Observable<void> {
    return this.post(`${this._relativeUrl}/award`, {
      projectId,
      memberId,
      amount: data.amount,
      deliveryDate: data.deliverydate,
    });
  }

  public editAwardProject(
    projectId: string,
    memberId: string,
    data: { amount: number; deliverydate: Date }
  ): Observable<void> {
    return this.put(`${this._relativeUrl}/award`, {
      projectId,
      memberId,
      amount: data.amount,
      deliveryDate: data.deliverydate,
    });
  }

  public completeProject(
    projectId: string,
    data: {
      isCompleted: boolean;
      projectRating?: number | undefined;
      meetTimeExpectations?: boolean | undefined;
      meetBudgetExpectations?: boolean | undefined;
    }
  ): Observable<void> {
    return this.post(`${this._relativeUrl}/complete`, {
      projectId,
      complete: data.isCompleted,
      rating: data.projectRating,
      timeExpectationMet: data.meetTimeExpectations,
      budgetExpectationMet: data.meetBudgetExpectations,
    });
  }

  public getChatToken(projectId: string): Observable<TokenResponse> {
    return this.get<TokenResponse>(`project-chat/${projectId}/token`);
  }

  public getPerformanceDetails(
    sustainmentId: string
  ): Observable<DetailsResponse[]> {
    return this.get<DetailsResponse[]>(
      `${this._relativeUrl}/performance/${sustainmentId}`
    );
  }

  public getPerformanceOverall(): Observable<OverallResponse[]> {
    return this.get<OverallResponse[]>(
      `${this._relativeUrl}/performance/overall`
    );
  }

  public getQuotes(projectId: string): Observable<Quote[]> {
    return this.get<Quote[]>(`quote/project/${projectId}`);
  }

  public addQuote(
    memberSustainmentId: string,
    quote: Quote,
    skipChatNotification: boolean = false
  ): Observable<Quote> {
    return this.post<Quote>(
      `quote/member/${memberSustainmentId}?skipChatNotification=${skipChatNotification}`,
      quote
    );
  }

  public getPurchaseOrder(projectId: string): Observable<PurchaseOrder[]> {
    return this.get<PurchaseOrder[]>(`PurchaseOrder/project/${projectId}`).pipe(
      map((pos) =>
        pos.sort((a, b) => {
          const aCreatedDate = new Date(a.createdAt!);
          const bCreatedDate = new Date(b.createdAt!);

          return bCreatedDate.getTime() - aCreatedDate.getTime();
        })
      )
    );
  }

  public addPurchaseOrder(po: PurchaseOrder): Observable<PurchaseOrder> {
    return this.post<PurchaseOrder>(`PurchaseOrder/`, po);
  }

  public respondPo(dto: AcceptDeclinePO): Observable<void> {
    return this.post('PurchaseOrder/respond', dto);
  }

  public updatePurchaseOrderFiles(
    dto: UpdatePurchaseOrderFilesDto
  ): Observable<void> {
    return this.put('PurchaseOrder/update-files', dto);
  }

  public getRecieveingQuotesProjects(): Observable<ReceivingQuoteProject[]> {
    return this.get('project/quoting');
  }

  public getDraftProjects(): Observable<DraftProject[]> {
    return this.get('project/draft');
  }

  public getAwardedProjects(): Observable<AwardedProject[]> {
    return this.get('project/awarded');
  }

  public getClosedProjects(): Observable<ClosedProject[]> {
    return this.get('project/closed');
  }

  public getDraftBoxUpload(): Observable<{ token: string; folderId: string }> {
    return this.get<{ token: string; folderId: string }>('drafts/upload-token');
  }

  public getItarSetting(projectId: string): Observable<boolean> {
    return this.get(`project/${projectId}/itar`);
  }
}

interface ProjectListModel {
  projects: IProjectList[];
  spaceUsed: number;
  spaceAmount: number;
}

export interface UserOpportunitiesResponse {
  closed: IOtherProjectList[];
  inProgress: IOtherProjectList[];
  lost: IOtherProjectList[];
  invitations: IOtherProjectList[];
  quoting: IOtherProjectList[];
}

export interface ProjectCreateRequestModel {
  name: string;
  description?: string;
  quoteDueDate?: Date;
  team?: string[];
  shortlist?: string[];
  purchaseOrder?: ProjectCreationPurchaseOrder;
  awardedSupplier?: string;
  settings?: ProjectCreationSettings;
  feedback?: ProjectCreationQuestionnaire;
  type: ProjectCreationTypeEnum;
  files?: string[];
}

interface ProjectCreationPurchaseOrder {
  deliveryDate: Date;
  pricing: number;
  files: ProjectCreationPurchaseOrderFile[];
}

interface ProjectCreationPurchaseOrderFile {
  fileId: string;
  fileName: string;
}

interface ProjectCreationSettings {
  itarCertificationRequired: boolean;
  signedNDARequired: boolean;
  memberCanDownloadFiles: boolean;
}

interface ProjectCreationQuestionnaire {
  rating: number;
  timeExpectationMet: boolean;
  budgetExpectationMet: boolean;
}

enum ProjectCreationTypeEnum {
  Private,
  Public,
  ToBeAwarded,
  Started,
  Delivered,
}

export interface ProjectMembersToken {
  sustainmentId: string;
  token: { token: string; folderId: string };
}
