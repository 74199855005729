/* eslint-disable  @typescript-eslint/no-shadow */

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { CoverAPI } from 'src/app/api/cover.api';
import { LogosAPI } from 'src/app/api/logos.api';
import { VendorService } from 'src/app/services/vendor.service';
import {
  IdentityApi,
  NotifyService,
  PdfDocumentAPI,
  PicturesAPI,
  VendorAPI,
  VendorFormatService,
  VendorProfileDocument,
  VendorProfilePicture,
} from 'sustainment-component';
import { FileVendorProfileCertification } from 'sustainment-component/lib/components/profile/certifications/certifications.component';
import {
  IContext,
  IMediaCreateRequest,
  IVendorPictureBase,
  UserSession,
  VendorCertificationRequest,
  VendorCreateRequest,
  VendorDetailsRequest,
  VendorExperienceRequest,
  VendorInfoRequest,
  VendorProcessRequest,
  VendorProcessResponse,
  VendorProfile,
  VendorProfileClassification,
  VendorProfileExperience,
  VendorProfileInfo,
} from 'sustainment-models';
import { StateQuery } from '../state/state.query';
import { UserAccountAction } from '../userAccount/user-account.action';
import { UserAccountQuery } from '../userAccount/user-account.query';
import { VendorSearchActions } from '../vendorsearch/vendor-search.action';
import { VendorStore } from './vendor.store';

// This needs to be standardized, as there are multiple codes
// that represent an international address in our databases. 143
// appears to be the most consistent one at the present time.
const OUTSIDE_US_STATE_CODE = 143;

@Injectable({ providedIn: 'root' })
export class VendorActions {
  private _sustainmentIdSubject = new BehaviorSubject<string | undefined>(
    undefined
  );
  public sustainmentId$ = this._sustainmentIdSubject.asObservable();

  public constructor(
    private _api: VendorAPI,
    private _store: VendorStore,
    private _pictureAPI: PicturesAPI,
    private _logosApi: LogosAPI,
    private _coverApi: CoverAPI,
    private _sanitaizer: DomSanitizer,
    private _pdfDocumentAPI: PdfDocumentAPI,
    private _stateQuery: StateQuery,
    private _router: Router,
    private _geoQueryAction: VendorSearchActions,
    private _vendorFormatService: VendorFormatService,
    private _vendorService: VendorService,
    private _userAccountQuery: UserAccountQuery,
    private _identityApi: IdentityApi,
    private _userAccountAction: UserAccountAction,
    private _notifyService: NotifyService
  ) {}

  public getVendorData(): void {
    const vendor$ = this._api.getCurrentVendorData().pipe(
      tap((vendor) => {
        if (!vendor?.name) {
          this._router.navigate(['/registration']);
          return;
        }
        const address =
          vendor?.addresses?.find((a) => a.isFavorite) || vendor?.addresses[0];
        this._geoQueryAction.updateMapCenter([
          address?.longitude || -97.6166,
          address?.latitude || 35.569,
        ]);
      }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

    const picturesData$ = vendor$.pipe(
      filter((vendor) => vendor !== null), // Only proceed if vendor is not null
      switchMap(() => this._api.getVendorPictures()), // Retrieve pictures
      startWith<IVendorPictureBase[]>([]),
      map((pictures) => {
        return pictures.map((p) => ({
          ...p,
          url: '/assets/icons/loading.gif',
        }));
      }),
      shareReplay(1)
    );

    const thumbnails$ = picturesData$.pipe(
      mergeMap((pictures) =>
        forkJoin(
          this._vendorService.getThumbnails(
            pictures,
            this._userAccountQuery.sustainmentId
          )
        )
      )
    );

    const documents$ = vendor$.pipe(
      filter((vendor) => vendor !== null), // Only proceed if vendor is not null
      switchMap(() => this._api.getVendorPdfList()) // Retrieve documents
    );

    vendor$.subscribe((vendor) => {
      this._store.update({
        vendor: {
          ...vendor,
          profileScore: this._vendorFormatService.calculateProfileScore(vendor),
          logoUrl: this._vendorFormatService.getLogo(
            vendor?.sustainmentId,
            vendor?.logo || ''
          ),
          coverUrl: this._vendorFormatService.getCover(
            vendor?.sustainmentId,
            vendor?.cover || ''
          ),
          industries: vendor?.industries?.map((industry) => ({
            ...industry,
            using: true,
          })),
        },
      });
      // Emit sustainmentId to the subject
      this._sustainmentIdSubject.next(vendor?.sustainmentId);
    });

    thumbnails$.subscribe((pictures) =>
      this._store.update(() => ({ pictures }))
    );

    documents$.subscribe((pdfList) =>
      this._store.update(() => ({ pdfDocuments: pdfList }))
    );
  }

  public createVendorV3(
    vendor: VendorCreateRequest
  ): Observable<UserSession | null> {
    return this._api.createVendorV3(vendor).pipe(
      switchMap(() => {
        const account = this._userAccountQuery.getValue();
        // When claiming a vendor, there's a internal
        // process to update the identity sustainmentId.
        // So here we fetch identity data to update the local user
        if (account.sustainmentId != vendor.sustainmentId) {
          return this._identityApi
            .getUserAccount({
              username: account.userAccount.userName,
              firstName: account.userAccount.firstName,
              lastName: account.userAccount.lastName,
            })
            .pipe(
              tap((userAcc) => this._userAccountAction.setUserAccount(userAcc)),
              switchMap((userAcc) =>
                this._identityApi.login(userAcc.organizations[0].sustainmentId)
              )
            );
        } else {
          return of(null);
        }
      }),
      tap((user) => {
        if (user) {
          this._userAccountAction.setUserSession(user);
        }
        this.getVendorData();
      })
    );
  }

  public updateVendorDetailsV3(
    details: VendorDetailsRequest
  ): Observable<void> {
    return this._api
      .updateVendorDetailsV3({
        ...details,
      })
      .pipe(
        tap(
          this._store.update((state) => {
            const vendor = { ...state.vendor, ...details };
            return { vendor };
          })
        )
      );
  }

  public updateVendorProcessesV3(
    vendorProcesses: VendorProcessRequest[]
  ): Observable<VendorProcessResponse[]> {
    return this._api.updateVendorProcessesV3(vendorProcesses).pipe(
      catchError((err: HttpErrorResponse) => {
        this._notifyService.showDanger(err.error.message);
        return throwError(err);
      }),
      tap((result) => {
        this._store.update((state) => {
          const vendor = {
            ...state.vendor,
            processes: result,
          };
          vendor.profileScore = this._vendorFormatService.calculateProfileScore(
            state.vendor
          );

          return { vendor };
        });
      })
    );
  }

  public updateVendorExperiencesV3(
    vendorExperiences: Array<
      VendorProfileExperience & { using: boolean; id: number }
    >
  ): Observable<void> {
    const experiences: VendorExperienceRequest[] = vendorExperiences
      .filter((e) => e.using == true)
      .map((e) => ({
        experience: e.experience,
        id: e.id,
        orderIndex: e.orderIndex,
        totalCustomersSupported: e.totalCustomersSupported,
        yearsExperience: e.yearsExperience,
        isFavorite: false,
      }));

    return this._api.updateVendorExperiencesV3(experiences).pipe(
      tap(() => {
        this._store.update((state) => {
          const vendor = {
            ...state.vendor,
            industries: vendorExperiences.filter((e) => e.using == true),
          };

          vendor.profileScore = this._vendorFormatService.calculateProfileScore(
            state.vendor
          );

          return { vendor };
        });
      })
    );
  }

  public updateVendorLogo(media: IMediaCreateRequest): Observable<unknown> {
    const logo$ =
      media && media?.mediaFile
        ? this._logosApi
            .uploadWithoutProgress(media)
            .pipe(map((r) => r.mediaObjectId))
        : of('');

    return logo$.pipe(
      mergeMap((data: string) =>
        this._api
          .patchVendorV3Data([
            {
              op: 'replace',
              path: '/logo',
              value: data !== '' ? data : undefined,
            },
          ])
          .pipe(map(() => data))
      ),
      tap((data) => {
        this._store.update((vendorState) => {
          const vendor = {
            ...vendorState.vendor,
            logo: data,
            logoUrl: this._vendorFormatService.getLogo(
              vendorState.vendor.sustainmentId,
              data
            ),
          };

          return {
            vendor,
          };
        });
      })
    );
  }

  public updateVendorCover(media: IMediaCreateRequest): Observable<unknown> {
    const logo$ =
      media && media?.mediaFile
        ? this._coverApi
            .uploadWithoutProgress(media)
            .pipe(map((r) => r.mediaObjectId))
        : of('');

    return logo$.pipe(
      mergeMap((data) =>
        this._api
          .patchVendorV3Data([
            {
              op: 'replace',
              path: '/cover',
              value: data,
            },
          ])
          .pipe(map(() => data))
      ),
      tap((data: string) => {
        this._store.update((vendorState) => {
          return {
            vendor: {
              ...vendorState.vendor,
              cover: data,
              coverUrl: this._vendorFormatService.getCover(
                vendorState.vendor.sustainmentId,
                data
              ),
            },
          };
        });
      })
    );
  }

  public updateVendorDataV3(
    companyInfo: VendorProfileInfo
  ): Observable<unknown> {
    const currentAddress = this._store.getValue().vendor.addresses[0];
    const stateContextInfo = this.getContextInfo(companyInfo, 'region');
    const stateData = stateContextInfo || companyInfo.address.place_name;
    const stateId = this.getStateId(stateData);
    const isInternational = this.getInternationalStatus(stateId);

    const newAddress = companyInfo.address.context
      ? {
          vendorAddressId: currentAddress?.vendorAddressId || 0,
          address: companyInfo.address.place_name.split(',')[0],
          postalCode: this.getContextInfo(companyInfo, 'postcod')?.text || '',
          stateId,
          latitude: companyInfo.address.center[1],
          longitude: companyInfo.address.center[0],
          city: this.getContextInfo(companyInfo, 'place')?.text || '',
          county: this.getContextInfo(companyInfo, 'district')?.text || '',
          country:
            this.getContextInfo(
              companyInfo,
              'country'
            )?.short_code.toUpperCase() || '',
          unit: '' /* We have no way of getting unit from mapbox */,
        }
      : {
          ...currentAddress,
          stateId: currentAddress.state.stateId,
          vendorAddressId: currentAddress.vendorAddressId || 0,
          latitude: currentAddress.latitude || 0,
          longitude: currentAddress.longitude || 0,
        };

    const request: VendorInfoRequest = {
      contactInfo: {
        email: companyInfo.email,
        fax: companyInfo.phone,
        linkedIn: companyInfo.linkedInProfile,
        phone: companyInfo.phone,
        website: companyInfo.website,
      },
      primaryAddress: newAddress,
    };

    return this._api.updateVendorV3Data(request).pipe(
      tap(() => {
        const stateQueryValue = this._stateQuery.getValue().states;

        this._store.update((vendorState) => {
          const vendor = {
            ...vendorState.vendor,
            website: companyInfo.website,
            linkedInProfile: companyInfo.linkedInProfile,
            phone: companyInfo.phone,
            email: companyInfo.email,
            addresses: [
              {
                ...newAddress,
                state: stateQueryValue.find(
                  (state) => state.stateId === newAddress.stateId
                )!,
                isFavorite: true,
                orderIndex: 1,
                unit: '',
              },
            ],
            isInternational,
          };

          return {
            vendor: {
              ...vendor,
              profileScore:
                this._vendorFormatService.calculateProfileScore(vendor),
            },
          };
        });
      })
    );
  }

  public patchVendorDataV3(patchData: any[]): Observable<unknown> {
    return this._api.patchVendorV3Data(patchData).pipe(
      tap(() => {
        this._store.update((state) => {
          const vendor = state.vendor;
          patchData.forEach((data) => {
            const pathSplit = data.path.split('/');
            const path = pathSplit.length > 0 ? pathSplit[1] : data.path;
            if (this.pathIsIVendorKey(path, vendor)) {
              (vendor[path] as string | null) = data.value;
            }
          });

          return {
            vendor: {
              ...vendor,
              profileScore:
                this._vendorFormatService.calculateProfileScore(vendor),
            },
          };
        });
      })
    );
  }

  public updateVendorPictures(
    pictures: VendorProfilePicture[]
  ): Observable<unknown> {
    const toUpload = pictures.filter((x) => !x.vendorPictureId);
    const uploads$ = toUpload.length
      ? forkJoin(
          toUpload.map((x) =>
            this._pictureAPI.uploadPhoto(x).pipe(
              mergeMap((res) =>
                this._api.postVendorPicture({
                  vendorPictureId: 0,
                  title: x.title,
                  fileName: x.fileName,
                  externalId: res.mediaObjectId,
                  isFavorite: false,
                  orderIndex: 0,
                })
              ),
              map((res) => ({
                ...res,
                url: x.url.toString(),
              }))
            )
          )
        )
      : of([]);

    const currentPictures = this._store.getValue().pictures;
    const toDelete =
      currentPictures?.filter(
        (x) =>
          !pictures.some(
            (y) => y.vendorPictureId && y.vendorPictureId === x.vendorPictureId
          )
      ) || [];
    const deletes$ = toDelete.length
      ? forkJoin(
          toDelete.map((x) =>
            this._pictureAPI.deletePicture(x.externalId).pipe(
              catchError((error) => {
                if (error.status === 404) {
                  return of(1);
                }
                throw error;
              }),
              mergeMap(() => this._api.deleteVendorPicture(x.vendorPictureId))
            )
          )
        )
      : of({});

    const toUpdate = pictures?.filter((x) => !!x.vendorPictureId) || [];
    const updates$ = toUpdate.length
      ? forkJoin(
          toUpdate.map((x) => {
            const current = currentPictures.find(
              (y) => x.vendorPictureId === y.vendorPictureId
            );
            if (!current) throw '';
            return this._api
              .putVendorPicture(x.vendorPictureId, {
                ...current,
                fileName: x.fileName,
                title: x.title,
                isFavorite: false,
              })
              .pipe(map((res) => ({ ...res, url: current.url })));
          })
        )
      : of([]);

    return combineLatest([uploads$, deletes$, updates$]).pipe(
      tap(([uploads, , updates]) =>
        this._store.update(() => ({
          pictures: [...uploads, ...updates],
        }))
      )
    );
  }

  public updateVendorPdfs(
    documents: VendorProfileDocument[]
  ): Observable<unknown> {
    const toUpload = documents.filter((x) => !x.id || x.mediaFile);
    const uploads$ = toUpload.length
      ? forkJoin(
          toUpload.map((x) =>
            this._pdfDocumentAPI.uploadPdf(x).pipe(
              mergeMap((res) => {
                const payload = {
                  title: x.title,
                  fileName: x.fileName,
                  externalId: res.mediaObjectId,
                  isFavorite: false,
                  orderIndex: 0,
                  vendorDocumentId: x.id,
                  documentType: x.type,
                };
                return x.id
                  ? this._api.putVendorPdf(x.id, payload)
                  : this._api.postVendorPdf(payload);
              }),
              map((res) => ({
                ...res,
                documentType: x.type,
                url: this._sanitaizer.bypassSecurityTrustUrl(
                  URL.createObjectURL(x.mediaFile)
                ),
              }))
            )
          )
        )
      : of([]);

    const currentDocuments = this._store.getValue().pdfDocuments;
    const toDelete = currentDocuments.filter((x) => {
      const updated = documents.find(
        (y) => y.id && y.id === x.vendorDocumentId
      );

      return !updated || (x.externalId && updated.mediaFile);
    });

    const deletes$ = toDelete.length
      ? forkJoin(
          toDelete.map((x) =>
            this._pdfDocumentAPI.deleteDocument(x.externalId).pipe(
              catchError((error) => {
                if (error.status === 404) {
                  return of(1);
                }
                throw error;
              }),
              mergeMap(() => {
                if (!documents.find((y) => y.id && y.id === x.vendorDocumentId))
                  return this._api.deleteVendorPdf(x.vendorDocumentId);
                return of(1);
              })
            )
          )
        )
      : of({});

    const toUpdate = documents.filter((x) => !!x.id && !x.mediaFile);
    const updates$ = toUpdate.length
      ? forkJoin(
          toUpdate.map((x) => {
            const current = currentDocuments.find(
              (y) => x.id === y.vendorDocumentId
            );
            if (!current) throw '';
            return this._api
              .putVendorPdf(x.id, {
                ...current,
                documentType: x.type,
                fileName: x.fileName,
                title: x.title,
                isFavorite: false,
              })
              .pipe(map((res) => ({ ...res, documentType: x.type })));
          })
        )
      : of([]);

    return combineLatest([uploads$, deletes$, updates$]).pipe(
      tap(([uploads, , updates]) =>
        this._store.update(() => ({
          pdfDocuments: [...uploads, ...updates],
        }))
      )
    );
  }

  public updateVendorCertifications(
    certifications: FileVendorProfileCertification[]
  ): Observable<unknown> {
    const temp = certifications.map((x, i) => ({ ...x, i }));

    const toUpload = temp.filter((x) => !!x.file?.mediaFile);
    const uploads$ = toUpload.length
      ? forkJoin(
          toUpload.map((x) =>
            this._pdfDocumentAPI.uploadPdf(x.file!).pipe(
              map((res) => ({
                i: x.i,
                externalId: res.mediaObjectId,
                fileName: res.fileName,
              }))
            )
          )
        )
      : of([]);

    const toDelete = temp
      .filter((x) => x.externalId && !!x.file?.mediaFile)
      .map((x) => ({ externalId: x.externalId, i: x.i }));

    const current = this._store.getValue().vendor.certifications || [];
    const removed = current.filter(
      (x) =>
        !!x.externalId &&
        !certifications.some(
          (cert) =>
            cert.vendorCertificationId &&
            x.vendorCertificationId === cert.vendorCertificationId
        )
    );
    toDelete.concat(removed.map((x) => ({ externalId: x.externalId, i: -1 })));

    const deletes$ = toDelete.length
      ? forkJoin(
          toDelete.map((x) =>
            this._pdfDocumentAPI.deleteDocument(x.externalId).pipe(
              map(() => ({
                i: x.i,
              }))
            )
          )
        )
      : of([]);

    return combineLatest([uploads$, deletes$]).pipe(
      mergeMap(([uploads, deletes]) => {
        const payload: VendorCertificationRequest[] = temp.map(
          (x): VendorCertificationRequest => {
            const value: VendorCertificationRequest = {
              fileName: x.fileName,
              externalId: x.externalId,
              vendorCertificationId: x.vendorCertificationId,
              name: x.name,
              certifier: x.certifier,
              expiresOnUtc: x.expiresOnUtc,
              receivedOnUTC: x.receivedOnUTC,
              orderIndex: 0,
              certificationId: x.certificationId || null,
              certificationInvalid: x.certificationInvalid,
            };

            const uploaded = uploads.find((upload) => x.i === upload.i);
            if (uploaded) {
              value.fileName = uploaded.fileName;
              value.externalId = uploaded.externalId;
              return value;
            }
            const deleted = deletes.find((del) => x.i === del.i);
            if (deleted) {
              value.fileName = null;
              value.externalId = null;
              return value;
            }

            return value;
          }
        );
        return this._api.updateVendorCertifications(payload);
      }),
      tap((x) =>
        this._store.update((state) => {
          const vendor = {
            ...state.vendor,
            certifications: x.map((y) => ({
              ...y,
              isFavorite: false,
              expiresOnUtc: y.expiresOnUtc || '',
              receivedOnUTC: y.receivedOnUTC || '',
              externalId: y.externalId || '',
              fileName: y.fileName || '',
              certificationInvalid: y.certificationInvalid || false,
            })),
          };

          return {
            vendor,
          };
        })
      )
    );
  }

  public updateVendorClassifications(
    vendorClassifications: VendorProfileClassification[]
  ): Observable<unknown> {
    const temp = vendorClassifications.map((x, i) => ({ ...x, i }));

    const toUpload = temp.filter((x) => !!x.file?.mediaFile);
    const uploads$ = toUpload.length
      ? forkJoin(
          toUpload.map((x) =>
            this._pdfDocumentAPI.uploadPdf(x.file!).pipe(
              map((res) => ({
                i: x.i,
                externalId: res.mediaObjectId,
                fileName: res.fileName,
              }))
            )
          )
        )
      : of([]);

    const toDelete = temp
      .filter((x) => x.externalId && !!x.file?.mediaFile)
      .map((x) => ({ externalId: x.externalId, i: x.i }));

    const current = this._store.getValue().vendor.classifications || [];
    const removed = current.filter(
      (x) =>
        !!x.externalId &&
        !vendorClassifications.some(
          (classification) =>
            classification.vendorClassificationId &&
            x.vendorClassificationId === classification.vendorClassificationId
        )
    );
    toDelete.concat(removed.map((x) => ({ externalId: x.externalId, i: -1 })));

    const deletes$ = toDelete.length
      ? forkJoin(
          toDelete.map((x) =>
            this._pdfDocumentAPI.deleteDocument(x.externalId!).pipe(
              map(() => ({
                i: x.i,
              }))
            )
          )
        )
      : of([]);

    return combineLatest([uploads$, deletes$]).pipe(
      mergeMap(([uploads, deletes]) => {
        const payload = temp.map((x) => {
          const result = {
            ...x,
          };
          const uploaded = uploads.find((upload) => x.i === upload.i);
          if (uploaded) {
            return {
              ...result,
              fileName: uploaded.fileName,
              externalId: uploaded.externalId,
            };
          }
          const deleted = deletes.find((del) => x.i === del.i);
          if (deleted) {
            return {
              ...result,
              fileName: null,
              externalId: null,
            };
          }

          return result;
        });
        return this._api.updateVendorClassifications(payload);
      }),
      tap((x) =>
        this._store.update((state) => {
          const vendor = {
            ...state.vendor,
            classifications: x.map((y) => ({
              ...y,
              vendorId: 0,
            })),
          };

          return { vendor };
        })
      )
    );
  }

  private pathIsIVendorKey(
    path: string,
    vendor: VendorProfile
  ): path is keyof VendorProfile {
    return path in vendor;
  }

  private getStateId(stateOrAddress: string | IContext | null): number {
    const stateQueryValue = this._stateQuery.getValue().states;

    if (typeof stateOrAddress === 'string') {
      const stateNames = stateQueryValue.map((state) => state.name);

      const addressParts = stateOrAddress.split(/[\s, ]+/);

      for (const stateName in stateNames) {
        if (addressParts.includes(stateNames[stateName])) {
          return stateQueryValue[stateName].stateId;
        }
      }
    }

    return (
      stateQueryValue.find((x) => x.name === (stateOrAddress as IContext)?.text)
        ?.stateId || OUTSIDE_US_STATE_CODE
    );
  }

  private getInternationalStatus(stateId: number): boolean {
    const stateQueryValue = this._stateQuery.getValue().states;
    const foundStateAbbreviation = stateQueryValue.find(
      (state) => state.stateId === stateId
    )?.abbreviation;

    return foundStateAbbreviation === 'OU';
  }

  private getContextInfo(
    saveItem: VendorProfileInfo,
    key: string
  ): IContext | null {
    return (
      saveItem.address.context?.find((item) => item.id.indexOf(key) > -1) ||
      null
    );
  }
}
