import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-malicious-file-warning-header',
  standalone: true,
  template: `
    <div
      style="display: flex;
      align-items: center;
      gap: 8px;"
    >
      <div>
        <i
          class="pi pi-exclamation-triangle"
          style="color: #DC8F25; font-size: 24px;"
        ></i>
      </div>
      <div
        style="color: #1F2937; font-size: 20px; font-family: Inter; font-weight: 700; line-height: 24px; word-wrap: break-word"
      >
        Warning: malicious file detected
      </div>
      <div (click)="closeDialog()" style="cursor: pointer; margin-left: auto">
        <i
          class="pi pi-times"
          style="color: #1F2937; margin-left: auto; font-size:24px;"
        ></i>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
})
export class MaliciousFileWarningHeaderComponent {
  public constructor(public ref: DynamicDialogRef) {}

  public closeDialog(): void {
    this.ref.close();
  }
}
