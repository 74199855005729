import { features } from './feature-toggle';

export const featConfig: FeatureOptions = {
  resources: true,
  discover: true,
  connections: true,
  settings: true,
  chat: true,
  projects: false,
  newProfileVersion: true,
  reportsSharedWithMe: true,
  opensearchActive: false,
  opensearchDynamicFiltersActive: false,
  '*': false,
};

type FeatureOptions = {
  [key: string]: boolean;
};

export const environment = {
  environmentName: 'prod',
  appName: 'Portal',
  production: true,
  vendorApiBaseUrl: 'https://api.sustainment.tech/vendorprofile/prod/api/',
  projectsApiBaseUrl: 'https://api.sustainment.tech/projects/prod/api/',
  identityApiBaseUrl: 'https://api.sustainment.tech/identity/prod/api/',
  mediaApiBaseUrl: 'https://api.sustainment.tech/media/prod/api/',
  searchMiddlewareApiBaseUrl:
    'https://api.sustainment.tech/searchvendors/prod/api',
  interactionsApiUrl: 'https://api.sustainment.tech/interactions/prod/api/',
  emailValidationApiUrl: 'https://public-api-dev.sustainment.io/email-valid',
  mapboxApiUrl: 'https://api.mapbox.com/',
  dataProducts: 'https://odysseus.sustainment.tech',
  platFormCommuncationsUrl:
    'https://api.sustainment.tech/communications/prod/api',
  okta: {
    baseUrl: 'https://sustainmenttech.okta.com',
    clientId: '0oa5yz5c8Rn6y063F5d6',
    googleId: '0oa61v0h1zS3segyR5d6',
    facebookId: '0oa61u1igMYLqDtF05d6',
    linkedinId: '0oa61syvz6Zgifw2I5d6',
  },
  timeoutWarning: 60 * 1000 * 10,
  featureConfig: featConfig,
  featureConfigNew: features,
  datadog: {
    clientToken: 'pub0b66f38d73f15bd1fb8cdc68c5c2c548',
    site: 'datadoghq.com',
    service: 'Portal-prod',
  },
  boxEnterpriseId: '827368190',
  portalGAToken: 'G-GQPTBT3TW8',
  segmentWriteKey: 'SHrgisk5gov9nPw7P4Hasga7y9WZMJCW',
};
