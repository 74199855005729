import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbActiveModal, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FeatureToggleDirective } from './directives/feature-toggle.directive';
import { NsnPipe } from './pipes/nsn.pipe';
import { ButtonModule } from 'primeng/button';
import {
  SustainmentComponentModule,
  WINDOW_PROVIDER,
} from 'sustainment-component';
import { NumberToWordsPipe } from './pipes/number-to-words.pipe';
import { FilterCompaniesPipe } from './pipes/companies-chat.pipe';
import { FilterProjectMembersPipe } from './components/features/dashboard/project-filter.pipe';

@NgModule({
  declarations: [
    FeatureToggleDirective,
    NsnPipe,
    NumberToWordsPipe,
    FilterCompaniesPipe,
    FilterProjectMembersPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    NgbToastModule,
    ReactiveFormsModule,
    ButtonModule,
    SustainmentComponentModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    NgbToastModule,
    ReactiveFormsModule,
    FeatureToggleDirective,
    NsnPipe,
    SustainmentComponentModule,
    NumberToWordsPipe,
    FilterCompaniesPipe,
    FilterProjectMembersPipe,
  ],
  providers: [WINDOW_PROVIDER, NgbActiveModal],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
