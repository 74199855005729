export const features: FeatureOptions = {
  projects: 'true',
  subscriptions: 'true',
  companyWeekArticles: 'false',
  fileSharing: 'true',
  opensearchActive: 'true',
  opensearchDynamicFiltersActive: 'false',
  allowUnregisteredVendorMessages: 'true'
};

type FeatureOptions = {
  [key: string]: any;
};
